import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { setLoginParams, } from './slices/userSlice.js';


const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'retcode',
    headerName: 'Состояние',
    width: 100,
    editable: true,
  },
  {
    field: 'userlogin',
    headerName: 'Login',
    width: 100,
    editable: true,
  },
  {field: 'inputdate',
      headerName: 'Время входа',
      headerAlign: 'center',  
      width: 180,
      editable: true,
      valueGetter: (value, row) => {
      let  d = new Date(row.inputdate);
      d = d.toLocaleString(d);
      return d;    
    }
  }, 
  {field: 'outputdate',
    headerName: 'Время выхода',
    headerAlign: 'center',    
    width: 180,
    editable: true,
    valueGetter: (value, row) => {
      let  d = new Date(row.outputdate);
      d = d.toLocaleString(d);
      return d;    
      }    
  },
  {field: 'userip',
    headerName: 'IP',
    headerAlign: 'center',  
    width: 200,
    editable: true,
  },
  {
    field: 'sesid',
    headerName: 'ID сессии',
    width: 100,
    editable: true,
  },
  {
    field: 'ish_packet_count',
    headerName: 'Исходящие пакеты',
    width: 100,
    editable: true,
  },
  {
    field: 'vhd_packet_count',
    headerName: 'Входящие пакеты',
    width: 100,
    editable: true,
  },
      
];

export default function AppProt() {

    const [users, setUsers] = React.useState([]);
    const [bWait,setWait] = React.useState(false);
    const loginRedux = useSelector( (state) =>state.user.blogin);     

    const clientUUIDRedux = useSelector( (state) =>state.user.clientUUID);  
    const serverUUIDRedux = useSelector( (state) =>state.user.serverUUID);           
    const serverRedux= useSelector( (state) =>state.server.addres);        
    const portRedux= useSelector( (state) =>state.server.port);

    const dispatch = useDispatch();

        const getApiData = async () => {
        const body = JSON.stringify({clientUUID:clientUUIDRedux, serverUUID:serverUUIDRedux});
        const b64 = window.btoa(body);                
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
/*         const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          mode:'cors',
          credentials:'omit',
          body: body,
      };        
*/
        let addr = serverRedux+":"+portRedux+"/api/logs";
        addr=addr+'?p='+b64;                
//         await fetch(addr,requestOptions)
         await fetch(addr)         
        .then((response) => response.json())
        .then((data) => {        
          if ((data instanceof Object) && (Array.isArray(data))){                     
            setUsers(data);
            setWait(true);
          } else {
            dispatch(setLoginParams(false));
            setUsers([]);
            setWait(true);            
          }  
        })
        .catch((error) => {
          console.error("Ошибка получения данных:", error);
        });
    }
    const asyncfetch = React.useCallback( ()=>{
      getApiData();
    },[]);      

    React.useEffect(() => {
        console.log(`Prot Window Create ${( new Date).toLocaleString()}`);
        asyncfetch();
        return ()=>{
          console.log(`Prot Window Close ${( new Date).toLocaleString()}`);          
        };
      },[asyncfetch]);

      
    if(!loginRedux) return <Navigate to="/" replace />;

    if(!bWait){
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent:'center', alignItems:'center' }}>
          <CircularProgress />
        </Box>      
      </>
    )}  
    else {
    return (
      
      <Box sx={{ height: '80%', width: '80%', m:'1rem',
        [`.${gridClasses.cell}.cold`]:{
          backgroundColor:'white',
          color:'blue',
        },
        [`.${gridClasses.cell}.hot`]:{
          backgroundColor:'red',
          color:'yellow',
        },        
        [`.${gridClasses.row}.even`]:{
          backgroundColor:'white',
          color:'black',
        },
        [`.${gridClasses.row}.odd`]:{
          backgroundColor:'#fffff0',
          color:'green',
        },        

      }}>
      <DataGrid
        rows={users}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          density:'compact',
        }}
        getCellClassName={(params) => {
          return '';
          // if (params.field === 'id' || params.value == null) {
          //   return '';
          // }
          // return (params.field === 'retcode' && params.value > 0) ? 'hot' : 'cold';
        }} 
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }        
        pageSizeOptions={[10]}        
        disableRowSelectionOnClick
      />
    </Box>
  );
    }
  }