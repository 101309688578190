import React  from 'react';
import Box from '@mui/material/Box';
import { Button, Alert, Stack, CircularProgress, ButtonGroup } from '@mui/material';
import { DataGrid,} from '@mui/x-data-grid';

import { useSelector, useDispatch} from 'react-redux';
import { setLoginParams, } from './slices/userSlice.js';
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Collapse from '@mui/material/Collapse';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

function StatUserRow(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [user, setUser] = React.useState([]);
    const [bLoad,setLoad] = React.useState(false);


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);  
    const [bError,setError] = React.useState(false);        

    const loginRedux = useSelector( (state) =>state.user.blogin);   
    const dispatch = useDispatch();
    const clientUUIDRedux = useSelector( (state) =>state.user.clientUUID);  
    const serverUUIDRedux = useSelector( (state) =>state.user.serverUUID);          
    const serverRedux= useSelector( (state) =>state.server.addres);        
    const portRedux= useSelector( (state) =>state.server.port);
         
    const columns = [
      {
        id: 'num',
        label: '№',
        minWidth: 40,
        align: 'left',
        format: (value) => value,
      },
      {
        id: 'inputdate',
        label: 'Вход',
        minWidth: 90,
        align: 'left',
        format: (value) => value,
      },
      {
        id: 'outputdate',
        label: 'Выход',
        minWidth: 90,
        align: 'left',
        format: (value) => value,
      },
      {
        id: 'dauer',
        label: 'Длительность',
        minWidth: 90,
        align: 'left',
        format: (value) => value,
      },          
      {
        id: 'userip',
        label: 'IP',
        minWidth: 90,
        align: 'left',
        format: (value) => value,
      },
      {
        id: 'userlogin',
        label: 'login',
        minWidth: 90,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
      },
    ];        

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };      
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    function ClickUser(e){
        console.log("ClickUser %s",row.login);
        if(!open) {
            setLoad(true);
            getUserData(row.id);
        }
        setOpen(!open);                
    }

    const getUserData = async (userid) => {
        console.log("getUserData start ... %s ",userid);      
        const body = JSON.stringify({clientUUID:clientUUIDRedux, serverUUID:serverUUIDRedux,login:row.login});
        const b64 = window.btoa(body);

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          mode:'cors',
          credentials:'omit',
          body: body,
      };    
         let addr = serverRedux+":"+portRedux+"/api/logs/user";        
         addr=addr+'?p='+b64;                                            
//         await fetch(addr,requestOptions)
         await fetch(addr)         
        .then((response) => response.json())
        .then((data) => {                              
          console.log('getUSERData end >>> ',data.length);          
          if ((data instanceof Object) && (Array.isArray(data))){  
            const data2 = data.map( (item)=>{
              let a = structuredClone(item);
              a.inputdate=(new Date(item.inputdate)).toLocaleString();
              a.outputdate=(new Date(item.outputdate)).toLocaleString(); 
              const diff = (new Date(item.outputdate))-(new Date(item.inputdate)); 
             
              const seconds = Math.floor(diff / 1000);
              const minutes = Math.floor(seconds / 60);
              const hours = Math.floor(minutes / 60);
              const days = Math.floor(hours / 24);
              const months = Math.floor(days / 30); // ~30
              const years = Math.floor(days / 365); // ~365                                  
              
              a.dauer = (new Date(years,months,days,(hours%24),(minutes%60),(seconds%60),0)).toTimeString().slice(0,8);
              return a;
            });        
            setUser(data2);
            setLoad(false);
          } else  {
            dispatch(setLoginParams(false));            
            setUser([]);
            setLoad(false);
          }
        })
        .catch((error) => {
          setError(true);
        });
    }
        

    return (
    <React.Fragment>          
        <TableRow key={props.key} >
            <TableCell> 
                <IconButton onClick={ClickUser}  >
                    {open ? <KeyboardArrowUpIcon color="primary" /> : <KeyboardArrowDownIcon color="primary"/>}
                </IconButton>
            </TableCell>
            <TableCell>{row.login}</TableCell><TableCell>{row.name}</TableCell><TableCell>{row.child}</TableCell><TableCell>{row.state}</TableCell><TableCell>{row.status}</TableCell>
        </TableRow>
        {open && <TableRow key={row.id}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open}  unmountOnExit>
                <Box sx={{ margin: 1 }}>
                    {bLoad &&  ( <Typography variant="h7" gutterBottom component="div">
                                    Loading  {row.login} ...
                                </Typography> )                
                    }
                    {!bLoad && <Box sx={{width:'75%', display:'flex', justifyContent: 'flex-end', alignContent:'flex-end',border:1, borderColor: 'primary.main',borderRadius: '8px'  }}>

                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>                       

                        <Table >
                        <TableHead  >
                            <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ top: 57, minWidth: column.minWidth }}
                              >
                              {column.label}
                            </TableCell>
                            ))}                                                         
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {user.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map( (r)=> {
                            return (
                              <TableRow key={r.num}>
                                {columns.map((column) => {
                                  const value=r[column.id];                                      
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                  : value}
                                  </TableCell>
                                );                                      
                                })}                                  
                            </TableRow>                                
                            );
                        })}      
                        </TableBody>       
                       
      </Table>
      </TableContainer>            
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          count={user.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}     
        />          

</Paper>

                    </Box>
                    }
                </Box>
            </Collapse>
        </TableCell>
        </TableRow>
        }
    </React.Fragment>
    )                
}    
export default StatUserRow;