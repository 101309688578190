// From https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem.
function base64ToBytes(base64) {
    const binString = atob(base64);
    return Uint8Array.from(binString, (m) => m.codePointAt(0));
  }
  
  // From https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem.
  function bytesToBase64(bytes) {
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString);
  }
  export function validUTF16StringEncoded (s) {
    return bytesToBase64(new TextEncoder().encode(s));
  }
    
  export function validUTF16StringDecoded(s) {
    return  new TextDecoder().decode(base64ToBytes(s));
  }  
  