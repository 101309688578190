import React, {useEffect, useState, useRef} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Alert, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import {validUTF16StringEncoded,} from './myLib.js';

export default function EditUser(props) {

const [recUser,setRecUser]= useState(props.recUser);  
const [recLogin,setRecLogin]= useState(props.recUser.login);  
const [recID,setID]= useState(props.recUser.id);  
const [status,setStatus]= useState('');  

//const loginRedux = useSelector( (state) =>state.user.blogin);  
const dispatch = useDispatch();
const clientUUIDRedux = useSelector( (state) =>state.user.clientUUID);  
const serverUUIDRedux = useSelector( (state) =>state.user.serverUUID);    
const serverRedux= useSelector( (state) =>state.server.addres);        
const portRedux= useSelector( (state) =>state.server.port);

const RefLogin = useRef('') //creating a refernce for TextField Component
const RefPassword = useRef('') //creating a refernce for TextField Component
const RefName = useRef('') //creating a refernce for TextField Component
const RefState = useRef('') //creating a refernce for TextField Component
const RefStatus = useRef('') //creating a refernce for TextField Component

const hClose =()=>{
    props.handleEditClose();
}

const updateUser = async () => {
    console.log(' updateUser (%s)',recUser);
    if( (RefState.current ===null ) || (RefState.current ==='' ) || (RefState.current === undefined )) {RefState.current =0 }
    if((RefStatus.current ===null ) || (RefStatus.current ==='' ) || (RefStatus.current === undefined )) {RefStatus.current =0 }    
    const body = JSON.stringify({clientUUID:clientUUIDRedux, serverUUID:serverUUIDRedux,typeSQL:props.typeSQL, 
        id:recUser.id, login:RefLogin.current.value, password:RefPassword.current.value, 
        name:RefName.current.value, status:RefStatus.current.value, state:RefState.current.value});
    const b64 =validUTF16StringEncoded(body);        
/*    const b64 = window.btoa(unescape(encodeURIComponent(body)));        

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        mode:'cors',
        credentials:'omit',
        body: body,
    };    
*/    
    let addr = serverRedux+":"+portRedux+"/api/user";
    addr=addr+'?p='+b64;                    
//    await fetch(addr,requestOptions)    
    await fetch(addr)        
   .then((response) => response.json())
   .then((data) => {          
     console.log(' data >>',data);
     if ((data instanceof Object) ){          
        if (data.rows>0){
            setStatus('');
            hClose();
        }else {
            setStatus('Ошибка внесения данных !!!');
        }
    } else {
        setStatus('Ошибка получения данных !!!');        
    }   
   })      
   .catch((error) =>{
    console.log("Ошибка обновления данных: %s", error);
    setStatus('Ошибка сервера !!!');
   })
};

useEffect(() => {   
        console.log('Edit User props  recUser=(%s) typeSQL=(%s) ',props.recUser, props.typeSQL);
        console.log('SetRecUser Edit User props.recUser >> ',recUser);        
  },[]);

return (
  
    <Box>
    <TextField disabled id="outlined-basic" label="ID" variant="outlined"  size="small" sx = {{width: 50,}} defaultValue={recUser.id}  />
    <TextField id="outlined-basic" label="Login" variant="outlined"  size="small" sx = {{width: 130,}} defaultValue={recUser.login} inputRef={RefLogin} />
    <TextField id="outlined-basic" label="Password" variant="outlined"  size="small" sx = {{width: 130,}} defaultValue={recUser.password} inputRef={RefPassword} />
    <TextField id="outlined-basic" label="Name" variant="outlined"  size="small" sx = {{width: 180}} defaultValue={recUser.name} inputRef={RefName} />
    <TextField id="outlined-basic" label="Status" variant="outlined"  size="small" sx = {{width: 80}} defaultValue={recUser.status} inputRef={RefStatus} />
    <TextField id="outlined-basic" label="State" variant="outlined"  size="small" sx = {{width: 80}} defaultValue={recUser.state}  inputRef={RefState} />
    <TextField disabled id="outlined-basic" label="Вход" variant="outlined"  size="small" sx = {{width: 190}} defaultValue={recUser.last_login}  />    
    <TextField disabled id="outlined-basic" label="Выход" variant="outlined"  size="small" sx = {{width: 190}} defaultValue={recUser.last_logout}  />
    <TextField disabled id="outlined-basic" label="IP" variant="outlined"  size="small" sx = {{width: 150}} defaultValue={recUser.last_ip}  />
    
    <Tooltip title="Сохранить">
        <IconButton  aria-label="add">
            <SaveIcon  color="primary" label="Сохранить" onClick= {updateUser} />
        </IconButton>
    </Tooltip>    
    <Tooltip title="Отменить">
        <IconButton  aria-label="cancel">
            <CancelIcon  color="secondary" onClick= {hClose} />
        </IconButton>
    </Tooltip> 
    {status.length>0 && <Alert variant="filled" severity="error">
        {status}
    </Alert>       
    }

</Box>
)   
} 
