import React from "react";
import { useSelector } from "react-redux";
import { YMaps, Map, Placemark,ZoomControl } from "react-yandex-maps";
import { Navigate } from 'react-router-dom';
import './Map.css';

export default function AppMap() {
    const loginRedux = useSelector( (state) =>state.user.blogin);   
    if(!loginRedux) return <Navigate to="/" replace/>;                        
    return (
    <div>
        <YMaps query={{ apikey: '28975057-6629-4f6e-b83d-26d63020b4d3', lang:'ru-RU' }}>
        <Map width={900} height={500} defaultState={{ center: [55.753215, 37.622504], zoom: 4 }  }>
            <Placemark geometry={[55.650556, 37.489014]} />            
            <Placemark 
        modules={['geoObject.addon.balloon', 'geoObject.addon.hint',]}
        geometry={[50.110924, 8.682127]}
        properties={{
          iconContent:'666',           
          hintContent: 'Server',           
          balloonContent: '<style>.tb th {border: 1px solid blue }</style><table class="tb"><tr><th>Адрес</th><th>IP</th></tr><tr><th>Germany</th><th>85.159.226.22</th></tr></table>',
        }} 
        options={{
          preset: 'islands#glyphIcon', 
          iconColor: '#ff0000',          
          iconGlyph: 'home',
          iconGlyphColor: 'blue',
          iconImageSize: [50, 50],
          iconImageOffset: [-50, -18],
          openEmptyBalloon: true,
        }}
      />            
            <ZoomControl options={{ float: "right" }} />                  
         </Map>   
        </YMaps>
    </div>
  );
}
