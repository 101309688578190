import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector, useDispatch} from 'react-redux';
//import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { setLoginParams, } from './slices/userSlice.js';
import {validUTF16StringEncoded,} from './myLib.js';
import { ruRU } from '@mui/material/locale';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'src',
    headerName: 'Источник',
    width: 100,
    editable: false,
  },
  {
    field: 'mes',
    headerName: 'Сообщение',
    width: 800,
    editable: false,
  },
  {field: 'inputdate',
      headerName: 'Время входа',
      headerAlign: 'center',  
      width: 180,
      editable: false,
      valueGetter: (value, row) => {
      let  d = new Date(row.inputdate);
      d = d.toLocaleString(d);
      return d;    
    }
  }, 
  {field: 'ttype',
    headerName: 'Тип',
    headerAlign: 'center',    
    width: 80,
    editable: false,
        
  },
  {field: 'sesid',
    headerName: 'ID сессии',
    headerAlign: 'center',  
    width: 200,
    editable: false,
  },
  {
    field: 'log_ex_id',
    headerName: 'ID Log',
    width: 100,
    editable: false,
  },
     
];

const AppLog = () =>{ 
  
    const [data, setData] = React.useState([]);
    const [bWait,setWait] = React.useState(false);
    const loginRedux = useSelector( (state) =>state.user.blogin);  
    const dispatch = useDispatch();

    const clientUUIDRedux = useSelector( (state) =>state.user.clientUUID);  
    const serverUUIDRedux = useSelector( (state) =>state.user.serverUUID);   
    const serverRedux= useSelector( (state) =>state.server.addres);        
    const portRedux= useSelector( (state) =>state.server.port);
    const IPRedux= useSelector( (state) =>state.user.IP);    

    const theme = createTheme({
      palette: {
        primary: { main: '#1976d2' },
      },
    }, ruRU);    

//    const navigate = useNavigate();   
  
      const getApiData = async () => {
        const body = JSON.stringify({clientUUID:clientUUIDRedux, serverUUID:serverUUIDRedux,strIP:IPRedux});
        const b64 =validUTF16StringEncoded(body);        

/*        const b64 = window.btoa(body);        
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          mode:'cors',
          credentials:'omit',
          body: body,
      };    
*/      
        let addr = serverRedux+":"+portRedux+"/api/log";
        addr=addr+'?p='+b64;        
//         await fetch(addr,requestOptions)         
         await fetch(addr)                  
        .then((response) => response.json())
        .then((data) => {          
            console.log(`GET Сервер /api/log/ data.log=${data.length}`);
            if ((data instanceof Object) && (Array.isArray(data))){              
              setData(data);
              setWait(true);            
            } else {
              dispatch(setLoginParams(false));
              setData([]);
              setWait(true);            
            }                                  
        })
        .catch((error) => {
          console.error(`Ошибка получения данных: ${error}`);
        });
    }
    const asyncfetch = React.useCallback( ()=>{
      getApiData();
    },[]);
      
    React.useEffect(() => {
        console.log(`Log Window Open ${(new Date).toLocaleString()}`);
        asyncfetch();
        return ()=> {        
          console.log(`Log Window Close ${(new Date).toLocaleString()}`);          
        }
      },[asyncfetch]);

      if(!loginRedux) return <Navigate to="/" replace />;  

    if(!bWait){
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent:'center', alignItems:'center' }}>
          <CircularProgress />
        </Box>      
      </>
    )}  
    else {
    return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: '80%', width: '80%', m:'1rem' }}>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          density:'compact',
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
      />
    </Box>
    </ThemeProvider>          
  );
    }
  };

  export default AppLog;