import React  from 'react';
import Box from '@mui/material/Box';
import { Alert, CircularProgress, } from '@mui/material';

import { useSelector, useDispatch} from 'react-redux';
import { setLoginParams, } from './slices/userSlice.js';
import StatUserRow from './StatUserRow.js';

import { Navigate } from "react-router-dom";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ruRU } from '@mui/material/locale';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';



function StatUser(){
    const loginRedux = useSelector( (state) =>state.user.blogin);   
    const dispatch = useDispatch();
    const clientUUIDRedux = useSelector( (state) =>state.user.clientUUID);  
    const serverUUIDRedux = useSelector( (state) =>state.user.serverUUID);          
    const serverRedux= useSelector( (state) =>state.server.addres);        
    const portRedux= useSelector( (state) =>state.server.port);
   

    const [users, setUsers] = React.useState([]);
    const [bWait,setWait] = React.useState(true);
    const [bError,setError] = React.useState(false);

    const [pageMain, setPageMain] = React.useState(0);
    const [rowsPerPageMain, setRowsPerPageMain] = React.useState(5);     
    const theme = createTheme({
      palette: {
        primary: { main: '#1976d2' },
      },
    }, ruRU);    

    const handleChangePageMain = (event, newPage) => {
      setPageMain(newPage);
    };      
    const handleChangeRowsPerPageMain = (event) => {
      setRowsPerPageMain(+event.target.value);
      setPageMain(0);
    };
     
    const getApiData = async () => {
        console.log("getApiData start ...");      
        const body = JSON.stringify({clientUUID:clientUUIDRedux, serverUUID:serverUUIDRedux});
        const b64 = window.btoa(body);

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          mode:'cors',
          credentials:'omit',
          body: body,
      };    
         let addr = serverRedux+":"+portRedux+"/api/users";  
         addr=addr+'?p='+b64;                                   
//         await fetch(addr,requestOptions)
         await fetch(addr)         
        .then((response) => response.json())
        .then((data) => {                              
          console.log('getapiData end >>> ',data.length);          
          if ((data instanceof Object) && (Array.isArray(data))){          
            setUsers(data);
            setWait(false);
          } else  {
            dispatch(setLoginParams(false));            
            setUsers([]);
            setWait(false);
          }
        })
        .catch((error) => {
          setError(true);
        });
    }

    const asyncfetch = React.useCallback( ()=>{
      getApiData();
    },[]);      

    React.useEffect(() => {
        console.log(`StatUser Window Create ${( new Date()).toLocaleString()}`);
        asyncfetch();
        return ()=>{
          console.log(`StatUser Window Close ${( new Date()).toLocaleString()}`);          
        };
      },[asyncfetch]);

    
      if(!loginRedux) return <Navigate to="/" replace />;  

    return (

        <Box  sx={{m:'1rem', width:'75%',}}>                    
        {bError && <Alert severity="warning" onClose={() => {setError(false)}}> Ошибка!!! </Alert>}
        {bWait &&  <Box sx={{ display: 'flex', justifyContent:'center', alignItems:'center' }}>
                      <CircularProgress size="3rem"/>
                  </Box>
        }
        {!bError && !bWait && (
        <ThemeProvider theme={theme}>
        <Paper>
        <TableContainer component={Paper} >
        <Table aria-label="collapsible table" >
            <TableHead><TableRow >
                <TableCell sx={{ width: '5%'}}></TableCell><TableCell>Login</TableCell><TableCell>Позывной</TableCell><TableCell>Сеансов</TableCell><TableCell>Состояние</TableCell><TableCell>Status</TableCell>
                </TableRow>                
            </TableHead>
            <TableBody>
            {users.slice(pageMain * rowsPerPageMain, pageMain * rowsPerPageMain + rowsPerPageMain).map( (row)=> {                          
              return (
                <StatUserRow key={row.id} row={row} />
              )
            })}        
            </TableBody>                
        </Table>              
        </TableContainer>
        <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={users.length}
              rowsPerPage={rowsPerPageMain}
              page={pageMain}
              onPageChange={handleChangePageMain}
              onRowsPerPageChange={handleChangeRowsPerPageMain}     
            />          
          </Paper>            
          </ThemeProvider>                    
        )}
      
        </Box>        
   

   )
}
export default StatUser;